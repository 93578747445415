






















































/* global
ioSocket
eTheme
*/
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import CookieLaw from 'vue-cookie-law'

interface Form {
  validate(): boolean;
}

@Component({
  components: { CookieLaw }
})
export default class Login extends Vue {
  logoPath = `/images/logos/${eTheme}/wpadding-white.svg`;
  nteTheme: boolean = eTheme === 'nte';
  imageClass = {
    'fill-width': true,
    'pa-10': this.nteTheme,
    'pt-0': this.nteTheme
  };

  loading = false;

  usernameRules = [
    (username: string) => username.length > 0 || 'Skriv inn brukernavn'
  ];

  passwordRules = [
    (password: string) => password.length > 0 || 'Skriv inn passord'
  ];

  username = '';
  password = '';

  logInFormError = '';

  async logInn () {
    this.logInFormError = ''
    this.loading = true

    const form: Form = (this.$refs.form as unknown) as Form
    const valid = form.validate()

    // error message handled automatically by form
    if (!valid) return

    try {
      await ioSocket.get('/Auth/local', {
        identifier: this.username,
        password: this.password
      })

      window.location.href = '/homepage'
    } catch (error) {
      if (error.message === 'Forbidden') {
        this.logInFormError = 'Feil brukernavn eller passord'
      } else {
        this.logInFormError = 'Ukjent feil, last siden på nytt og prøv igjen'
        console.error('Log in error', error)
      }

      this.loading = false
    }
  }
}
